import React from 'react';
import FontAwesomeIcon from 'react-fontawesome'
import './styles/customEvent.scss'

export default class CustomEvent extends React.Component {
    render() {
      const closeColor = this.props.event.deleteColor;
        return (
        <div className="custom-event-container" title={this.props.event.tooltipAccessor}>
          <div>
            {this.props.title}
          </div>
          {this.props.event.showDelete ?
            <div style={{
              position: 'absolute',
              left: 0,
              top: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              paddingLeft: 'calc(100% - 28px)',
            }}>
                <svg onClick={() => this.props.event.handleDelete()} width="14" height="14" viewBox="0 0 14 14" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M13.3593 0.821847C13.612 1.07454 13.612 1.48425 13.3593 1.73694L1.71204 13.3842C1.45934 13.6369 1.04964 13.6369 0.796944 13.3842C0.544248 13.1315 0.544248 12.7218 0.796944 12.4691L12.4442 0.821847C12.6969 0.56915 13.1066 0.56915 13.3593 0.821847Z" fill={closeColor}/>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M0.796944 0.821847C1.04964 0.56915 1.45934 0.56915 1.71204 0.821847L13.3593 12.4691C13.612 12.7218 13.612 13.1315 13.3593 13.3842C13.1066 13.6369 12.6969 13.6369 12.4442 13.3842L0.796944 1.73694C0.544248 1.48425 0.544248 1.07454 0.796944 0.821847Z" fill={closeColor}/>
                </svg>
            </div>
            :
            <div className="booked" style={{color: this.props.event.bookingColor}} onClick={() => this.props.event.history.push("/dashboard/applicant-details/"+this.props.event.applicantId)}>
              予
            </div>
          }
        </div>
        );
    }
}
